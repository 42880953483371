<template>
  <v-container class="py-7">
    <v-form ref="form" class="mb-4">
      <div class="heading text-center mb-7 dark--text">
        Восстановление пароля
      </div>
      <v-img
        :src="$store.getters['app/logo']"
        height="80"
        center
        contain
        class="mx-auto mb-7"
      />
      <v-text-field
        class="mb-4"
        label="Телефон"
        v-mask="phoneMask"
        type="tel"
        outlined
        hide-details
        v-model="phone"
        :rules="[(v) => !!v]"
      />

      <div v-if="timeLeft > 0" class="mb-3 text-center primary--text">
        Получить новый код можно через {{ timeLeft }} сек.
      </div>

      <div class="text-center">
        На указанный телефон
        <br />придет новый пароль
      </div>
    </v-form>
  </v-container>
</template>

<script>
import actionButtonMixin from '@/mixins/actionButton'
import timerMixin from '@/mixins/timerMixin'

export default {
  mixins: [actionButtonMixin, timerMixin],

  data: () => ({
    phone: '',
    loading: false,
  }),

  computed: {
    actionButton() {
      return {
        active: true,
        text: 'Отправить',
        action: this.submit,
        loading: this.loading,
        disabled: this.timeLeft > 0,
      }
    },

    phoneMask() {
      return this.$store.getters['app/phoneMask']
    },
  },

  methods: {
    async submit() {
      const { commit, dispatch } = this.$store
      if (!this.validate()) return
      this.loading = true
      // const login = this.phone.replace(/\D+/g, "");
      const login = this.phone
      const response = await dispatch('user/requestForgotPass', login)
      if (response.code == 1) {
        this.commitTime()
        this.$router.push({ name: 'Login' })
      }
      commit('snackbar/update', {
        active: true,
        text: response.msg,
      })
      this.loading = false
    },

    validate() {
      return this.$refs.form.validate()
    },
  },
}
</script>
